<template>
    <div class="modal fade" id="deletecontact" tabindex="-1" role="dialog" aria-labelledby="contact" aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered " role="document">
            <div class="modal-content d-flex align-items-center" v-if="success">
                <successMessage :message="success" />
                <button @click="hideModal" class="__close__success__modal">Close</button>
            </div>
            <div class="modal-content pb-3" v-if="!success">
                <div class="modal-body m-0 p-0">
                    <div class="row justify-content-center" style="padding-right:1em; padding-left:1em;">
                        <div class="continue_edit_header pb-2 disable-birthday">
                            <div class="_edit_icon">
                                <img src="../../../assets/Delete.svg" height="33px" width="31px" alt="bin icon for delete">
                            </div>
                            <h3 class="continue_edit_header_title">
                                Are you sure you want to disable the Birthday App?
                            </h3>
                            <p class="__contacts_in_group sub-messages">
                                Disabling the birthday app would prevent clients from receiving messages <br/> during their
                                birthday.
                            </p>
                        </div>
                        <Alert v-if="errors" :message="errors" :type="type" />
                    </div>
                    <div class="modal__footer__btns">
                        <div class="deleteWrapper">
                            <el-button @click="confirmDisable"
                                style="margin-right:1.5em;padding-right:2em; padding-left:2em" :loading="loading"
                                type="danger">
                                Disable
                            </el-button>
                            <el-button style="margin-right:1.5em;padding-right:2em; padding-left:2em" @click="hideModal"
                                type="primary">
                                Cancel
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import store from '@/state/store.js'
let $ = require('jquery')
export default {
    name: 'create-delete-contact',
    data() {
        return {
            errors: '',
            success: '',
            loading: false,
        }
    },
    methods: {
        hideModal() {
            $('#deletecontact').modal('hide')
            this.success = false
        },
        confirmDisable(){
            this.$emit('confirmDisable')
            this.hideModal()
        }
        // deleteContact() {
        //     this.errors = '';
        //     this.loading = true
        //     let formData = this.contactForm.formData
        //     let payload = {
        //         contact_id: formData.contact.id,
        //         key: formData.key,
        //         group_id: formData.contact.group_id
        //     }
        //     store.dispatch('contacts/deleteContact', payload)
        //         .then(response => {
        //             this.success = response.data.message
        //         })
        //         .catch(err => {
        //             this.loading = false
        //             if (err.response.data == undefined) {
        //                 this.errors = 'Network error try again'
        //             }
        //             if (err.response && err.response.status === 404) {
        //                 this.loading = false
        //                 this.errors = err.response.data.data.error;
        //             }
        //         })
        // },
    }
}
</script>
<style scoped>
.modal__footer__btns {
    display: flex;
    margin: 2.5em 1em;
    justify-content: space-between;
}

.deleteWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact_image {
    height: 243px;
    background: #F6F6F6;
    border-radius: 10px 10px 0px 0px;
    padding: 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact_detail_header {
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #575761;
}

.contact_main_content {
    padding: 1em;
}

.contact_text_detail_header {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: #484A4F;
    margin-bottom: .7em !important;
}

.contact_detail_text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    color: #484A4F;
}

.continue_edit_header {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

._edit_icon {
    margin-top: 4em;
    margin-bottom: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.continue_edit_header_title {
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #575761;
}

.continue_edit_header_text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #575761;
    padding-top: .3em;
}

.__contacts_in_group {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #575761;
    margin-top: 1em;
}

.disable-birthday {
    display: block;
}

.sub-messages{
    font-size: 11px;
    color: hsl(0, 100%, 54%);
}
</style>