<template>
  <div
    class="modal fade"
    id="senderID"
    tabindex="-1"
    role="dialog"
    aria-labelledby="contact"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content d-flex align-items-center" v-if="success">
        <successMessage :message="success" />
        <button @click="hideModal" class="__close__success__modal">
          {{ $t("misc.button.close") }}
        </button>
      </div>
      <div
        class="modal-content"
        v-if="!success && senderModalState.type == 'delete'">
        <div class="modal-body m-0 p-0">
          <div class="row justify-content-center align-items-center mx-4">
            <div class="continue_edit_header pb-2">
              <div class="_edit_icon text-center">
                <img
                  src="@/assets/Delete.svg"
                  height="33px"
                  width="31px"
                  alt="bin icon for delete" />
              </div>
              <h3 class="continue_edit_header_title">
                {{ $t("dashboard.sender_id.delete") }}
              </h3>
            </div>
          </div>
          <div
            class=""
            v-if="errors"
            style="margin-left: 5em; margin-right: 4em">
            <Alert :message="errors" :type="type" />
          </div>
          <div class="modal__footer__btns">
            <el-button
              @click="deleteSenderID"
              style="margin-right: 1.5em; padding-right: 2em; padding-left: 2em"
              :loading="loading"
              type="danger"
              tabindex="0">
              {{ $t("misc.button.delete") }}
            </el-button>
            <el-button
              style="margin-right: 1.5em; padding-right: 2em; padding-left: 2em"
              @click="hideModal"
              type="primary"
              tabindex="1"
              class="notranslate">
              {{ $t("misc.button.cancel") }}
            </el-button>
          </div>
        </div>
      </div>
      <div
        class="modal-content"
        v-show="!success && senderModalState.type == 'newSenderID'">
        <div class="modal-header pt-4 pb-2">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ senderModalState.title }}
          </h5>
        </div>
        <div class="modal-body m-0 p-0">
          <div
            class="row justify-content-center"
            style="padding-right: 1em; padding-left: 1em">
            <div class="col-md-12">
              <el-form
                :model="formData"
                :rules="rules"
                ref="ruleForm"
                @submit.prevent.native="checkEnter"
                @keyup.enter.native="submit('ruleForm')">
                <div class="row justify-content-center">
                  <div class="col-md-12">
                    <div class="form_input_container">
                      <el-form-item
                        :label="$t('dashboard.sender_id.label')"
                        prop="sender_id">
                        <el-input
                          type="text"
                          autocomplete="off"
                          :placeholder="$t('dashboard.sender_id.placeholder')"
                          v-model="formData.sender_id"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form_input_container">
                      <el-form-item
                        :label="$t('dashboard.sender_id.purpose')"
                        prop="purpose">
                        <el-input
                          type="textarea"
                          :rows="7"
                          autocomplete="off"
                          :placeholder="
                            $t('dashboard.sender_id.placeholder_purpose')
                          "
                          v-model="formData.purpose"></el-input>
                      </el-form-item>
                    </div>
                    <div class="__sender_id_text">
                      <i18n
                        path="dashboard.sender_id.via_api"
                        for="dashboard.sender_id.here">
                        <template v-slot:here>
                          <a
                            href="https://readthedocs.mnotify.com/#tag/Campaign/operation/sender_id/get"
                            target="__blank"
                            >{{ $t("dashboard.sender_id.here") }}</a
                          >
                        </template>
                      </i18n>
                    </div>
                  </div>
                </div>
                <span v-if="errors">
                  <Alert :message="errors" :type="type" />
                </span>
              </el-form>
            </div>
          </div>
          <div
            class="modal__footer__btns"
            v-if="senderModalState.type == 'newSenderID'">
            <button class="cancel notranslate" @click="hideModal">
              {{ $t("misc.button.cancel") }}
            </button>
            <el-button
              :loading="loading"
              type="primary"
              id="__create__btn"
              @click="submit('ruleForm')">
              <img
                src="../../../assets/3 User.svg"
                alt="user profile icon" />&nbsp;
              {{ senderModalState.btnTitle }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import store from "@/state/store.js";
  let $ = require("jquery");
  export default {
    name: "create-new-group",
    props: {
      userData: {
        required: false,
      },
    },
    data() {
      return {
        success: "",
        errors: "",
        loading: false,
        type: "alert-danger",
        formData: {
          sender_id: "",
          purpose: "",
        },
        rules: {
          sender_id: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
            {
              max: 11,
              message: this.$t("dashboard.sender_id.min"),
              trigger: ["change", "blur"],
            },
          ],
          purpose: [
            {
              required: true,
              message: this.$t("dashboard.sender_id.purpose_sender_id"),
              trigger: "change",
            },
          ],
        },
      };
    },
    mounted() {
      // store.dispatch('senderId/setSenderModalState')
    },
    computed: {
      senderModalState() {
        return store.getters["senderid/senderModalState"];
      },
      groups() {
        return store.getters["groups/all_groups"];
      },
    },
    methods: {
      hideModal() {
        this.$emit("hideModal");
        this.success = "";
        this.loading = false;
        this.errors = false;
      },
      deleteSenderID() {
        this.errors = "";
        this.loading = true;
        this.success = "";

        store
          .dispatch("senderid/deleteSenderID", this.userData)
          .then((response) => {
            this.loading = false;
            (this.success = response.data.message),
              (this.formData = {
                sender_id: "",
                purpose: "",
              });
          })
          .catch((err) => {
            this.loading = false;
            if (err.response?.data == undefined) {
              this.errors = "Network error try again";
            }
            if (err.response && err.response.status === 404) {
              this.errors = err.response.data.data.error;
            }
          });
      },
      submit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.errors = "";
            this.loading = true;
            store
              .dispatch("senderid/registerSenderID", this.formData)
              .then((response) => {
                this.loading = false;
                (this.success = response.data.message),
                  (this.formData = {
                    sender_id: "",
                    purpose: "",
                  });
              })
              .catch((err) => {
                this.loading = false;
                if (err.response?.data == undefined) {
                  this.errors = "Network error try again";
                }
                if (err.response && err.response.status === 404) {
                  if (err.response.data.data.sender_id) {
                    return (this.errors = err.response.data.data.sender_id[0]);
                  }
                  this.errors = err.response.data.data.error;
                }
              });
          } else {
            return false;
          }
        });
      },
    },
  };
</script>
<style scoped>
  ._edit_icon {
    margin-top: 4em;
    margin-bottom: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .continue_edit_header_title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #575761;
  }

  .__sender_id_text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: #979797;
  }

  .__sender_id_text a {
    text-decoration: none;
    color: #f7921c;
  }

  .modal__footer__btns {
    display: flex;
    margin: 2em 1em;
    justify-content: space-between;
  }

  .cancel {
    background: #f5f5f5;
    width: 123.8px;
    height: 40px;
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    color: #9e9ea7;
    outline: none;
    border: none;
  }

  #__create__btn {
    height: 40px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form_container {
    display: flex;
    justify-content: center;
  }
</style>
